<template>
  <section>
    <b-row>
      <b-col
        cols="12"
        xl="9"
        md="8"
      >
        <b-form @submit.prevent>
          <b-card
            no-body
            class="py-3"
          >
            <b-card-body class="pt-0">
              <b-row>
                <b-col
                  cols="12"
                  md="12"
                >
                  <b-form-group>
                    <b-overlay
                      :show="loading"
                      rounded="sm"
                    >
                      <label
                        v-if="!loading"
                      >{{ 'Add notes to user ' + demoUser.email }}</label>
                      <b-form-textarea
                        id="textarea-default"
                        v-model="notes"
                        rows="3"
                      />
                    </b-overlay>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
        </b-form>
      </b-col>

      <!-- Right Col: Card -->
      <b-col
        cols="12"
        md="4"
        xl="3"
        class="mt-md-0 mt-2"
      >
        <!-- Action Buttons -->
        <b-card>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mb-75"
            block
            @click="saveNotes()"
          >
            Save
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            block
            @click="goBack()"
          >
            Cancel
          </b-button>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BButton,
  BCard,
  BCardBody,
  BCol,
  BForm,
  BFormGroup,
  BFormTextarea, BOverlay,
  BRow,
  VBToggle,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import axios from '@/libs/axios'

export default {
  components: {
    BFormTextarea,
    BCard,
    BRow,
    BCol,
    BForm,
    BCardBody,
    BFormGroup,
    BButton,
    BOverlay,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  data: () => ({
    notes: null,
    loading: true,
    demoUser: null,
  }),
  created() {
    this.fetchData()
  },
  methods: {
    async fetchData() {
      const url = `/api/admin/leads/${this.$route.params.id}`
      await axios
        .get(url)
        .then(response => {
          this.notes = response.data.notes
          this.demoUser = response.data
          this.loading = false
        })
    },
    saveNotes() {
      const url = `/api/admin/leads/${this.$route.params.id}`
      const data = {
        notes: this.notes,
      }
      axios
        .post(url, data)
        .then(() => {
          this.notify({
            text: 'Note updated!',
            variant: 'success',
          })
          this.goBack()
          switch (this.demoUser.status) {
            case 0: this.$store.dispatch('users/refreshRejectedLeads')
              break
            case 1: this.$store.dispatch('users/refreshPendingLeads')
              break
            case 2: this.$store.dispatch('users/refreshCallbackLeads')
              break
            case 3: this.$store.dispatch('users/refreshCalledLeads')
              break
            default: //
          }
        })
    },
    goBack() {
      this.$router.go(-1)
    },
  },
}
</script>
